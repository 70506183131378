import React from 'react'
import { Link } from 'gatsby'
import { Container, Grid, IconButton, Hidden } from '@material-ui/core'
import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: 'rgba(255,255,255,.6)',
    '&:hover': {
      background: 'transparent',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: '1.5rem',
    width: '1em',
    height: '1em',
  },
}))

const Footer = styled.footer`
  font-size: 1rem;
  color: ${({ theme }) => theme.palette.common.white};
  background: #2b2b2b;
`

const FooterContainer = styled(Container)`
  margin-top: 6rem;
  padding-top: 3rem;
  padding-bottom: 3rem;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;
  }
`

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: 2rem;
  }
`

const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.palette.common.white};
  padding: 0.4rem 0;
  font-weight: 400;
`


const Copyright = styled.div`
  font-weight: 300;
  font-size: 0.78rem;
  font-weight: 300;
  line-height: 150%;
`

const PrivacyLink = styled(Link)`
  color: ${({ theme }) => theme.palette.common.white};
  font-size: 0.78rem;
  font-weight: 300;
  line-height: 120%;
  padding: 0 0.75rem;
  &:first-child {
    padding-left: 0;
  }
  &:not(:last-child) {
    border-right: 0.0625rem solid ${({ theme }) => theme.palette.common.white};
  }
`

const StyledDivider = styled.hr`
  margin: 2.2rem 0rem 1rem;
  border: .5px solid #df1f26;

  
`
const LogoContainer = styled.div`
  width: 145px;
  heght: 47px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin: 1rem auto;
  }
`

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`
const Phone = styled.a`
color: ${({ theme }) => theme.palette.common.white};
padding: 0.4rem 0;
font-weight: 400;
  }
`
export default () => {
  const classes = useStyles()

  return (
    <Footer>
      <FooterContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={3}>
            <Hidden xsDown>
              <LogoContainer>
                <Link to="/">
                  {/* <StyledImg
                    src="/assets/logo/logo_white.svg"
                    alt="MediaOptions.com logo"
                  /> */}
                </Link>
              </LogoContainer>
            </Hidden>

            
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FooterLinks>
    
             
              <Phone 
              href="tel:+1-508-336-9393"
              aria-label="Seekonk Car Storage phone number">
             +1-508-336-9393
            </Phone>
            </FooterLinks>
          </Grid>
         
        </Grid>

        <StyledDivider />
        <Grid container>
          <Grid item xs={12} md={9}>
            <Hidden smUp>
              <LogoContainer>
                <Link to="/">
                  {/* <StyledImg
                    src="/assets/logo/logo_white.svg"
                    alt="Seekonk Car Storage logo"
                  /> */}
                </Link>
              </LogoContainer>
            </Hidden>
            <Copyright>
              &copy; 2020 <a href="https://www.sdlyons.com/">S.D. Lyons Inc </a>
            
            </Copyright>
            <Copyright></Copyright>
          </Grid>
        </Grid>
      </FooterContainer>
    </Footer>
  )
}
