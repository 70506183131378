import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Hero from "../components/home/hero";
import Services from "../components/home/services"
// styles
const IndexPage = ({ data }) => {
  const services = data.contentfulPage.items
  return (
    <Layout>
      <section style={{background: "#2b2b2b", padding:"20px"}}>
       <Hero hero={data.contentfulPage.hero}/>
      </section>

      <section>
        {services.map((service)=>{
          return(
          <Services key={service.id} service={service} /> 
          )
        })}
      </section>
    </Layout>
  );
};



export const query = graphql`
  query HomePageQuery {
    contentfulPage(slug: { eq: "/" }) {
      metaTitle
      metaDescription
      hero {
        heading
        text {
          text
        }
      }
      items {
        heading
        subheading
        text {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;
export default IndexPage;
