import React from 'react'
import Header from '../header/header'
import { Helmet } from 'react-helmet'
import { theme } from '../utils/theme'
import { StylesProvider } from '@material-ui/core/styles'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core'
import { ThemeProvider as ScThemeProvider } from 'styled-components'
import Footer from '../footer/footer'
import { createGlobalStyle } from 'styled-components';
import CookieConsent from "react-cookie-consent";


import chevron from '../../static/chevronList.svg'
// import JSONdata from '../../static/content/7tWnyf2MJi9yAK5AQfI9c3.json'

// const Message = styled.p`
 
// `
const GlobalStyle = createGlobalStyle`
  
  body {
  font-family:  'Arial';
  font-weight: 400;
  }
 
  p {
   font-size: 1.1rem;
   line-height: 153.5%;
   color: ${({ theme }) => theme.palette.grey['800']};
   margin-bottom: 1.4rem;
  }
  
  li {
   font-size: 1.2rem;
   line-height: 153.5%;
   font-weight: 100;
   color: ${({ theme }) => theme.palette.grey['700']};
   margin-top: 1.4rem;
  }
  h1 {
    font-family: 'M PLUS Rounded 1c', 'Arial';
    font-size: 2.7rem;
    font-weight: 700;
    line-height: 125%;
    margin: 3rem auto 2rem;
    color: ${props => props.theme.palette.grey['800']};
  }
  h2 {
    font-family: 'M PLUS Rounded 1c', 'Arial';
    font-size: 2rem;
    font-weight: 700;
    line-height: 125%;
    margin: 3rem auto 2rem;
    color: ${props => props.theme.palette.grey['800']};
  }
  h3 {
    font-family: 'Arial';
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 120%;
    margin: 3rem auto 2rem;
    color: ${props => props.theme.palette.grey['800']};
  }

  .bullet-list li {
  display: list-item;
  padding-left: 1rem;
  }

  .bullet-list ul {
  width: 75%;
  display: block;
  list-style-image: url(${chevron});
  margin-block-start: 2em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
 }
 strong {
    font-weight: 700;
  }
  a {
    text-decoration: none;
  }
`

const Providers = props => (
  <MuiThemeProvider theme={theme}>
    <ScThemeProvider theme={theme}>
      <StylesProvider>
        <GlobalStyle />
        {props.children}</StylesProvider>
    </ScThemeProvider>
  </MuiThemeProvider>
)

export default function Layout({ children }) {
  return (
    <Providers>
      <Helmet
         htmlAttributes={{lang: "en-US"}}
      >
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
     
      </Helmet>
      <Header style={{position: "fixed"}}/>
      <main style={{paddingTop: "95px"}}>
        {children}
        <CookieConsent
          buttonText={"accept"}
          style={{ color: "#ffffff", fontSize: ".75rem"}}
        >
        {/* {JSONdata.message} */}
          </CookieConsent>
        </main>
      <Footer />
    </Providers>
  )
}
