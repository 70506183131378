import React from 'react'
import { Link } from 'gatsby'
import BurgerNav from '../header/burger-nav'
import Tooltip from '../components/tooltip'
import styled from 'styled-components'
import { Container, Hidden } from '@material-ui/core'

import logo from '../../static/logo.png'

const HeaderWrapper = styled.header`
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;
  background: ${({ theme }) => theme.palette.common.white};
`

const HeaderContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding: 1.5rem 1rem;
  }
`

const LogoContainer = styled.div`
   {
    width: 120.47px;
    height: 39.25px;
  }
`

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`

const RightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Nav = styled.nav`
  > * {
    padding: 0.2rem 1rem;
    &:not(:last-child) {
      border-right: 1px solid ${props => props.theme.palette.grey['500']};
    }
  }
`
const HeaderButton = styled.button`
  font-size: 1rem;
  color: ${props => props.theme.palette.grey['500']};
  background: transparent;
  border: 1px solid transparent;
  &:hover {
    color: ${props => props.theme.palette.grey['600']};
  }
`
const HeaderLink = styled(Link)`
  font-size: 1rem;
  color: ${props => props.theme.palette.grey['500']};
  text-decoration: none;
  &:hover {
    color: ${props => props.theme.palette.grey['600']};
  }
`

const Phone = styled.a`
font-size: 1rem;
color: ${props => props.theme.palette.grey['500']};
text-decoration: none;
&:hover {
  color: ${props => props.theme.palette.grey['600']};
}
`

const MenuList = styled.ul`
  font-size: 1rem;
  color: ${props => props.theme.palette.grey['500']};
  margin: 0;
  padding: 0 0.5rem;
`

const StyledListItem = styled.li`
  font-size: 1rem;
  color: ${props => props.theme.palette.grey['500']};
  list-style-type: none;
  margin: 0;
  padding: 0.25rem 0;
`

const ServicesMenu = () => (
  <MenuList>
    <StyledListItem>
      <Link to="/premium-car-storage">Premium Car Storage</Link>
    </StyledListItem>
    <StyledListItem>
      <Link to="/level-2-car-storage">Level II Car Storage</Link>
    </StyledListItem>
    <StyledListItem>
      <Link to="/evidence-storage">Evidence Storage</Link>
    </StyledListItem>
    <StyledListItem>
      <Link to="/motorcycle-storage">Motorcycle Storage</Link>
    </StyledListItem>
  </MenuList>
)



const tooltipProps = {
  placement: 'bottom',
  delayHide: 150,
}

const Header = () => (
  <HeaderWrapper>
    <HeaderContainer>
      <Hidden mdUp implementation="css">
        <BurgerNav />
      </Hidden>
      <LogoContainer>
        <Link to="/">
          <StyledImg src={logo} alt="Seekonk Car Storage logo" />
        </Link>
      </LogoContainer>
      <RightSection>
        <Hidden smDown implementation="css">
          <Nav>
            <Phone 
              href="tel:+1-508-336-9393"
              aria-label="Seekonk Car Storage phone number">
             <strong> + 1-508-336-9393</strong>
            </Phone>

{/* 
            <Tooltip {...tooltipProps} tooltip={<ServicesMenu />}>
              <HeaderButton aria-controls="Services-dropdown">
                Services
              </HeaderButton>
            </Tooltip>
            <HeaderLink to="/about">About</HeaderLink>
            <HeaderLink to="/faqs">FAQs</HeaderLink>
            <HeaderLink to="/blog">Blog</HeaderLink> */}
          </Nav>
        </Hidden>
      </RightSection>
    </HeaderContainer>
  </HeaderWrapper>
)

export default Header
