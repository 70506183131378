import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as typeformEmbed from '@typeform/embed'
import Img from "gatsby-image";
import {
  Container,
  Grid,
  Button,
  GridList,
  GridListTile,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components"

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  gridList: {
    width: 960,
  },
}));

const StyledHeading = styled.h1`
  color: #f3f2f2;
  text-align: center;
  ${({ theme }) => `

${theme.breakpoints.up('xs')} {
  font-size: 2rem;
}
${theme.breakpoints.up('sm')} {
  font-size: 2.7rem;
}
${theme.breakpoints.up('md')} {
  text-align: left;
  
}
${theme.breakpoints.up('lg')} {
  font-size: 3.3rem;
 
}
`}
`
const StyledDiv = styled.p`
  margin: 2rem auto;
  color: #f3f2f2;
  strong {
      color: #df1f26;
  }
`
const ButtonWrapper = styled.div`
 ${({ theme }) => `
 ${theme.breakpoints.down('sm')} {
   
 }
   margin: 3rem auto;
 `}
`

const StyledButton = styled(Button)`
${({ theme }) => `
${theme.breakpoints.down('md')} {
 font-size: 1.2rem;
}
margin-right: 1rem;
font-size: 1.4rem;
text-transform: none;
color: ${theme.palette.common.white};
`
}
`
const url = `https://i97h5okfnon.typeform.com/to/Kggiv13n`
  const openForm = () => {
    const popup = typeformEmbed.makePopup(`${url}`, {
      source: 'SeekonkCarStorage.com',
      mode: 'drawer_left',
      hideScrollbars: true
    })
    popup.open()
  }

export default ({ hero }) => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query MyQuery {
      allFile {
        edges {
          node {
            id
            name
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                  }
            }
          }
        }
      }
    }
  `);

  const heroImages = data.allFile.edges;

  
  return (
    <Container>
       <div className={classes.root}>
      <GridList cellHeight="auto" className={classes.gridList}cols={3} >
        {heroImages.map(({node}) => (
          <GridListTile key={node.id} >
            <Img fluid={node.childImageSharp.fluid} alt={node.name} />
          </GridListTile>
          
        ))}
      </GridList>
    </div>
    <Grid container>
        
        <Grid item xs={12} md={7}>
        <StyledHeading>Seekonk Car Storage</StyledHeading>

        <StyledDiv dangerouslySetInnerHTML={{ __html: hero.text.text }} />
        <ButtonWrapper>
          <StyledButton 
            variant="contained" 
            color="primary"
            onClick={openForm}
            >
            Get a car storage quote
          </StyledButton>
          </ButtonWrapper>
        </Grid>
    </Grid>
    </Container>
  );
};
