import * as React from "react"
import { Container, Grid } from "@material-ui/core";


const Services = ({service})=>(
    <Container>
      <Grid container>
          <Grid item xs={12} md={4}>
              <h2>{service.heading}</h2>
              <p>{service.subheading}</p>
              <div 
                dangerouslySetInnerHTML={{
                    __html: service.text.childMarkdownRemark.html
                  }}
              />

          </Grid>
      </Grid>
    </Container>
)

export default Services