import React from 'react'
import TooltipTrigger from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'
import styled from 'styled-components'

const StyledToolTip = styled.div`
  min-width: 140px;
  a { font-weight: 200;
  color: ${props => props.theme.palette.grey['500']};
  text-decoration: none;
  &:hover {
  color: ${props => props.theme.palette.grey['500']};
  text-decoration: underline;
   }
 }
`
    
 const Tooltip = ({ children, tooltip, hideArrow, ...props }) => (
   <TooltipTrigger
     {...props}
     tooltip={({
       arrowRef,
       tooltipRef,       
       getArrowProps,
       getTooltipProps,
       placement,
       
     }) => (
       <StyledToolTip
         {...getTooltipProps({
           ref: tooltipRef,
           className: 'tooltip-container',
         })}
       >
         {!hideArrow && (
           <div
             {...getArrowProps({
               ref: arrowRef,
               className: 'tooltip-arrow',
               'data-placement': placement,
             })}
           />
         )}
         {tooltip}
       </StyledToolTip>
     )}
   >
     {({ getTriggerProps, triggerRef }) => (
       <span
         {...getTriggerProps({
           ref: triggerRef,
           className: 'trigger',
         })}
       >
         {children}
       </span>
     )}
   </TooltipTrigger>
)
 
export default Tooltip


