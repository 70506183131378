import React, { useCallback, useState } from 'react'
import {
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import styled from 'styled-components'

const StyledIconButton = styled(IconButton)`
  padding: 0.5rem;
`

const Phone = styled.a`
font-size: 1rem;
color: ${props => props.theme.palette.grey['500']};
text-decoration: none;
&:hover {
  color: ${props => props.theme.palette.grey['600']};
}
`

const useStyles = makeStyles((theme) => ({
  root: () => ({
    color: theme.palette.blue.main,
    fontSize: `1.75rem`,
    padding: `0rem`
  }),
  iconButton: {
    padding: 0,
  }
  
}));

const NavItem = props => {
  const [open, setOpen] = useState(false)

  const handleClick = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      setOpen(!open)
    },
    [open]
  )

  let Icon = null
  let submenu = null
  if (props.items) {
    Icon = open ? ExpandLess : ExpandMore
    submenu = (
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.items}
      </Collapse>
    )
  }

  return (
    <>
      <ListItem
        button
        component="a"
        href={props.href}
        style={{
          paddingLeft: `${2 * (props.indent ?? 0.5)}rem`,
          paddingRight: Icon ? '0' : '0.25rem',
        }}
      >
        <ListItemText primary={props.name} />
        {Icon && (
          <StyledIconButton onClick={handleClick}>
            <Icon />
          </StyledIconButton>
        )}
      </ListItem>
      {submenu}
    </>
  )
}






const BurgerNav = (props) => {

  const classes = useStyles(props)
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => setOpen(true), [])
  const handleClose = useCallback(() => setOpen(false), [])

  return (
    <>
      <IconButton
        arial-label="open navigation"
        onClick={handleOpen}
        className={classes.iconButton}
        >
        
        <MenuIcon className={classes.root} />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={handleClose}>
        <List>
        <Phone 
              href="tel:+1-508-336-9393"
              aria-label="Seekonk Car Storage phone number">
             <strong> + 1-508-336-9393</strong>
            </Phone>
          <Divider />
         
         
         
        </List>
      </Drawer>
    </>
  )
}

export default BurgerNav
