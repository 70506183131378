import { createMuiTheme } from '@material-ui/core/styles'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'

// extend the MUI theme palette with our own custom colors
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    green: Palette['primary']
    blue: Palette['primary']
    red: Palette['primary']
    orange: Palette['primary']
  }
  interface PaletteOptions {
    green: PaletteOptions['primary']
    blue: PaletteOptions['primary']
    red: PaletteOptions['primary']
    orange: PaletteOptions['primary']

  }
}

const grey: PaletteOptions['grey'] = {
   50: '#F7F7F7',
  100: '#E1E1E1',
  200: '#CFCFCF',
  300: '#B1B1B1',
  400: '#9E9E9E',
  500: '#7E7E7E',
  600: '#626262',
  700: '#515151',
  800: '#3B3B3B',
  900: '#222222', 
}

const green: PaletteOptions['green'] = {
  100: '#f0fff4',
  200: '#c6f6d5',
  300: '#9ae6b4',
  400: '#68d391',
  500: '#48bb78',
  600: '#38a169',
  700: '#2f855a',
  800: '#276749',
  900: '#22543d',
  main: '#009EB6',
}

const blue: PaletteOptions['blue'] = {
  100: '#FAFEFF',
  200: '#bee3f8',
  300: '#90cdf4',
  400: '#63b3ed',
  500: '#4299e1',
  600: '#3182ce',
  700: '#2b6cb0',
  800: '#2c5282',
  900: '#2a4365',
  main: '#0071BC',
}
const orange: PaletteOptions['orange'] = {
  100: '#fffaf0',
  200: '#feebc8',
  300: '#fbd38d',
  400: '#f6ad55',
  500: '#ed8936',
  600: '#dd6b20',
  700: '#c05621',
  800: '#9c4221',
  900: '#7b341e',
  main: '#F48737',
}

const red: PaletteOptions['red'] = {
  main: '#E55154',
}

export const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: blue,
    secondary: { main: '#009eb6' },
    warning: red,
    success: green,
    green,
    blue,
    red,
    orange,
    grey,
  },
  breakpoints: {
    values: {
      xs: 200,
      sm: 600,
      md: 1100,
      lg: 1280,
      xl: 1920,
    },
  },
})
